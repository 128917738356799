import { configureStore } from '@reduxjs/toolkit';
import { createContext } from 'react';
import type { ReactReduxContextValue } from 'react-redux';
import { createDispatchHook, createSelectorHook } from 'react-redux';

import rootReducer from 'Store/Reducers';

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export const WpsContext = createContext<ReactReduxContextValue<RootState>>(null);

type WpsDispatch = (typeof store)['dispatch'];
export const useWpsDispatch = createDispatchHook<RootState>(WpsContext).withTypes<WpsDispatch>();
export const useWpsSelector = createSelectorHook(WpsContext).withTypes<RootState>();
