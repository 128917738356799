const MAIN_SET_LOADING = 'MAIN_SET_LOADING';
const MAIN_SET_TRENDING_ARTICLES = 'MAIN_SET_TRENDING_ARTICLES';
const MAIN_SET_HASHTAGS = 'MAIN_SET_HASHTAGS';
const MAIN_SET_ARTICLE_PREVIEW = 'MAIN_SET_ARTICLE_PREVIEW';
const MAIN_SET_SPONSORS = 'MAIN_SET_SPONSORS';

const NAVBAR_SELECT_HASHTAG = 'NAVBAR_SELECT_HASHTAG';
const NAVBAR_SET_TITLE = 'NAVBAR_SET_TITLE';
const NAVBAR_SET_CLASS = 'NAVBAR_SET_CLASS';
const NAVBAR_SET_IMAGE_BACKGROUND = 'NAVBAR_SET_IMAGE_BACKGROUND';
const NAVBAR_RESET = 'NAVBAR_RESET';

export default {
  MAIN_SET_LOADING,
  MAIN_SET_TRENDING_ARTICLES,
  MAIN_SET_HASHTAGS,
  MAIN_SET_ARTICLE_PREVIEW,
  MAIN_SET_SPONSORS,
  NAVBAR_SELECT_HASHTAG,
  NAVBAR_SET_TITLE,
  NAVBAR_SET_CLASS,
  NAVBAR_SET_IMAGE_BACKGROUND,
  NAVBAR_RESET,
};
