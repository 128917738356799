import types from 'Store/Actions/types';

const initialState = {
  mainMedia: null,
  navTitle: '',
  navClass: '',
  selectedHashtag: '',
};

const selectHashtag = (state, action) => {
  return {
    ...state,
    selectedHashtag: action.payload.selectedHashtag,
  };
};

const setNavTitle = (state, action) => {
  return {
    ...state,
    navTitle: action.payload.navTitle,
  };
};

const setNavClass = (state, action) => {
  return {
    ...state,
    navClass: action.payload.navClass,
  };
};

const setImageBackground = (state, action) => {
  return {
    ...state,
    mainMedia: action.payload,
  };
};

const resetNavbar = (state) => {
  return {
    ...state,
    mainMedia: null,
    navTitle: '',
    navClass: '',
    selectedHashtag: '',
  };
};

const reducer = (state = initialState, action) => {
  state = { ...state };
  switch (action.type) {
    case types.NAVBAR_SELECT_HASHTAG:
      return selectHashtag(state, action);
    case types.NAVBAR_SET_TITLE:
      return setNavTitle(state, action);
    case types.NAVBAR_SET_CLASS:
      return setNavClass(state, action);
    case types.NAVBAR_SET_IMAGE_BACKGROUND:
      return setImageBackground(state, action);
    case types.NAVBAR_RESET:
      return resetNavbar(state);
    default:
      return state;
  }
};

export default reducer;
