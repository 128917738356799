import types from 'Store/Actions/types';
import type { Sponsor } from 'types/sponsor';

type GeneralState = {
  sponsors: Sponsor[];
  loading: boolean;
  [key: string]: any;
};

const initialState: GeneralState = {
  loading: false,
  trendingArticles: [],
  sectionArticles: [],
  hashtags: [],
  articlePreview: null,
  sponsors: [],
};

const setLoading = (state, action): GeneralState => {
  return {
    ...state,
    loading: action.payload.loading,
  };
};

const setTrendingArticles = (state, action): GeneralState => {
  return {
    ...state,
    trendingArticles: action.payload,
  };
};

const setHashtags = (state, action): GeneralState => {
  return {
    ...state,
    hashtags: action.payload.hashtags,
  };
};

const setArticlePreview = (state, action): GeneralState => {
  return {
    ...state,
    articlePreview: action.payload,
  };
};

const setSponsors = (state, action: { type: string; payload: Sponsor[] }): GeneralState => {
  return {
    ...state,
    sponsors: action.payload.filter((s) => ['GLOBAL', 'LOCAL'].includes(s.type)),
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAIN_SET_LOADING:
      return setLoading(state, action);
    case types.MAIN_SET_TRENDING_ARTICLES:
      return setTrendingArticles(state, action);
    case types.MAIN_SET_HASHTAGS:
      return setHashtags(state, action);
    case types.MAIN_SET_ARTICLE_PREVIEW:
      return setArticlePreview(state, action);
    case types.MAIN_SET_SPONSORS:
      return setSponsors(state, action);
    default:
      return state;
  }
};

export default reducer;
